<template>
  <div class="activity-stats">
    <v-row class="w-100">
      <v-col cols="12" md="12">
        <!-- table -->
        <div class="list-header">
          <div
            class="list-head d-flex w-100 justify-space-between align-center px-6 pt-5"
          >
            <span>
              <p class="gray--text mr-4 mt-1">Stats ({{ listItems.length }})</p>
            </span>
            <div class="d-flex"></div>
            <base-input
              placeholder="Search Stats"
              @input="searchStats"
              :rules="[]"
            ></base-input>
          </div>
          <div class="pa-4 px-7">
            <div
              class="py-2 text-center radius-5 secondary cursor-pointer"
              @click="addNewStat"
            >
              <span class="white--text font-16">Add New Stat</span>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="listItems"
          :item-class="
            (item) => {
              return 'cursor-pointer';
            }
          "
          class="users-table"
          height="calc(100vh - 140px - 80px)"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ 'items-per-page-options': [5, 15, 30] }"
          :server-items-length="total"
          mobile-breakpoint="0"
          @click:row="viewItem"
          @update:page="fetchList"
          @update:items-per-page="fetchList"
          @update:sort-by="fetchList"
          @update:sort-desc="fetchList"
          fixed-header
        >
          <template v-slot:item.icon="{ item }">
            <div>
              <img v-if="item.icon" :src="item.icon" class="mt-2" />
            </div>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-icon
              class="cursor-pointer"
              color="error"
              @click="deleteConfirm(item, $event)"
              >mdi-delete</v-icon
            >
          </template>
          <template v-slot:item.inputType="{ item }">
            <p class="d-block my-1 py-0">
              {{ item.inputType == 0 ? "List" : "Value" }}
            </p>
          </template>
          <template v-slot:item.list="{ item }">
            <div v-if="item.selections && item.selections.length > 0">
              <div
                class="d-flex"
                v-for="selection in item.selections"
                :key="selection._id"
              >
                <img
                  class="d-block"
                  :src="selection.icon"
                  v-if="selection.icon"
                />
                <p class="d-block my-1 py-0">{{ selection.value }}</p>
              </div>
            </div>
            <div class="d-flex" v-else>
              <p class="d-block my-1 py-0 mx-2">-</p>
            </div>
          </template>
          <template v-slot:item.categories="{ item }">
            <div v-if="item.categories && item.categories.length > 0">
              <div
                class="d-flex"
                v-for="category in item.categories"
                :key="category._id"
              >
                <p
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  class="d-block my-1 py-0 gray lighten-3 rounded nowrap"
                >
                  {{ category.name }}
                </p>
              </div>
            </div>
            <div class="d-flex" v-else>
              <p class="d-block my-1 py-0 mx-2">-</p>
            </div>
          </template>
          <template v-slot:item.unit="{ item }">
            <p class="d-block my-1 py-0">{{ item.unit }}</p>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-model="newStatDialog" max-width="750px">
        <new-stat
          :key="newStatDialog"
          @close="newStatDialog = false"
          @reloadData="fetchList"
        ></new-stat>
      </v-dialog>
      <v-dialog
        v-model="statDialog"
        content-class="white px-6 py-7"
        max-width="500"
      >
        <edit-stats-dialog
          :key="statDialog"
          :paramID="paramID"
          @close="statDialog = false"
          @reloadData="fetchList"
        ></edit-stats-dialog>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import NewStat from "@/components/dialogs/ActivityStat.vue";
import EditStatsDialog from "@/components/dialogs/EditStats.vue";
const SortEnum = {
  NAMEASC: 1,
  NAMEDESC: 2,
  UNITASC: 3,
  UNITDESC: 4,
  INPUTTYPEASC: 5,
  INPUTTYPEDESC: 6,

  init: function (sortBy, sortDesc) {
    const descSuffix = sortDesc ? "DESC" : "ASC";
    return this[
      `${sortBy
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .toUpperCase()}${descSuffix}`
    ];
  }
};
export default {
  components: {
    NewStat,
    EditStatsDialog
  },
  data: () => ({
    newStatDialog: false,
    total: 0,
    paramID: "",
    categories: [],
    obj: {
      selections: [{ value: "", icon: undefined, id: 0 }],
      icon: undefined,
      inputType: undefined
    },
    valid: false,

    listItems: [],
    headers: [
      { text: "Icon", value: "icon", width: "75px" },
      { text: "Name", value: "name", width: "100px" },
      { text: "Input Type", value: "inputType", width: "100px" },
      { text: "List", value: "list", width: "100px", sortable: false },
      { text: "Unit", value: "unit", width: "80px" },
      {
        text: "Categories",
        value: "categories",
        sortable: false,
        width: "85px"
      },
      { text: "Delete", value: "delete", width: "85px", sortable: false }
    ],
    deleteDialog: false,
    deleteItem: "",
    page: 1,
    sortBy: undefined,
    sortDesc: true,
    itemsPerPage: 15,
    searchWord: undefined,
    statDialog: false
  }),
  methods: {
    viewItem(item) {
      this.paramID = item._id;
      this.statDialog = true;
    },
    deleteConfirm(item, event) {
      this.deleteDialog = true;
      this.deleteItem = item;
      event.stopPropagation();
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        `/activityStats/${this.deleteItem._id}`
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        await this.fetchList();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    addNewStat() {
      // this.newStatDialog = true;
      this.paramID = undefined;
      this.statDialog = true;
    },
    async fetchCategories() {
      if (this.categories.length == 0) {
        let { data } = await this.$axios.get("activity/all/selectors");
        if (data) {
          this.categories = data.categories;
          this.categories.forEach((cat) => (cat.checked = false));
        }
      }
    },
    async searchStats(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
    },
    async fetchList() {
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const search =
        this.searchWord && this.searchWord.length > 0
          ? `&searchWord=${this.searchWord}`
          : "";
      const sort = this.sortBy
        ? `&sort=${SortEnum.init(this.sortBy, this.sortDesc)}`
        : "";
      var pageSize = this.itemsPerPage;
      let { data } = await this.$axios.get(
        `activityStats?pageSize=${pageSize}&pageNumber=${this.page}${sort}${search}`
      );
      if (data) {
        this.listItems = data.stats;
        this.listItems.forEach((item) => {
          item.categories = item.categories.filter((category) => {
            return this.categories.find((cat) => cat._id == category._id);
          });
        });
        this.total = data.count;
      }
    },
    async reloadData() {
      await this.fetchList();
    }
  },
  async created() {
    await this.fetchCategories();
    await this.fetchList();
  }
};
</script>

<style lang="scss" scoped>
.activity-stats {
  height: 100vh;
  overflow: hidden;
  .activity-cover {
    background: white;
  }
  .users-table {
    overflow: auto;
  }
  .list-header {
    height: 140px;
  }
}
</style>
