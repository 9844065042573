<template>
  <div class="white pa-5 py-3">
    <v-row class="w-100">
      <v-col cols="12" md="12" class="">
        <v-form v-model="valid" @submit.prevent="submitHandler" ref="statform">
          <div class="px-6 mt-4 mb-10">
            <v-row>
              <!-- type -->
              <v-col cols="12" sm="6" md="4" class="pb-0">
                <label class="font-13 grey--text">Stat Type</label>
                <base-select
                  :key="obj.inputType"
                  :items="types"
                  placeholder="Stat Type"
                  v-model="obj.inputType"
                  item-value="value"
                  itemText="title"
                  :hideDetails="false"
                  :returnObject="false"
                  :rules="[
                    (item) => {
                      return true;
                    }
                  ]"
                ></base-select>
              </v-col>
            </v-row>
            <v-row>
              <!-- name -->
              <v-col cols="12" sm="6" md="4" class="mt-0 pt-0 pb-0">
                <label class="font-13 grey--text">Stat Name</label>
                <base-input
                  placeholder="Stat Name"
                  v-model="obj.name"
                  :hideDetails="false"
                ></base-input>
              </v-col>

              <!-- value -->
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="mt-0 pt-0 pb-0"
                v-if="obj.inputType == 1"
              >
                <label class="font-13 grey--text">Stat Unit</label>
                <base-input
                  placeholder="Stat Unit"
                  v-model="obj.unit"
                  :hideDetails="false"
                ></base-input>
              </v-col>
              <!-- upload icon  -->
              <v-col cols="5" md="2" class="py-0">
                <!-- upload new -->
                <label class="font-13 grey--text mt-0">Main Icon</label>
                <div class="upload-item whitebg">
                  <div>
                    <v-icon v-if="!obj.icon" color="grayicon"
                      >mdi-folder-multiple-image</v-icon
                    >
                    <img v-if="obj.icon" :src="obj.icon" class="grayicon" />
                  </div>
                  <v-file-input
                    :key="obj.icon && obj.icon.name ? obj.icon.name : obj.icon"
                    @change="uploadIcon"
                    accept="image/*"
                    hide-details="true"
                    :rules="[
                      () => {
                        return obj.icon != undefined;
                      }
                    ]"
                  ></v-file-input>
                  <v-icon
                    class="upload-item-delete"
                    size="16"
                    @click="this.obj.icon = ''"
                    >mdi-close</v-icon
                  >
                </div>
              </v-col>
            </v-row>

            <!-- selects if list -->
            <div v-if="obj.inputType == 0" class="pl-2">
              <v-row>
                <v-col
                  cols="7"
                  class="pt-1 pb-0 pl-0 w-100"
                  v-for="(select, i) in obj.selections"
                  :key="i"
                >
                  <v-row class="justify-space-center">
                    <v-col
                      md="2"
                      v-if="obj.selections && obj.selections.length > 1"
                    >
                      <div
                        class="base-btn mt-5"
                        @click="removeSelectHandler(select)"
                      >
                        <v-icon color="error">mdi-minus-circle-outline</v-icon>
                      </div>
                    </v-col>

                    <!-- Option name -->
                    <v-col md="6">
                      <div class="w-100">
                        <label class="grey--text font-12 font-500"
                          >Option Name</label
                        >
                        <div class="select-input">
                          <base-input
                            placeholder="name"
                            v-model="select.value"
                          ></base-input>
                        </div>
                      </div>
                    </v-col>

                    <v-col md="4">
                      <!-- upload new -->
                      <label class="grey--text font-12 font-500"
                        >Option Icon</label
                      >
                      <div>
                      <label class="black--text font-12">Save the Stat first</label>
                    </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="pl-0">
                  <div>
                    <div class="base-btn d-flex" @click="addNewSelectHandler">
                      <v-icon color="primary">mdi-plus</v-icon>
                      <div class="d-block ml-2">Add Option</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <!-- categories -->
            <label class="font-15 black--text mt-4 d-block">Categories</label>
            <v-row>
              <v-col
                cols="6"
                sm="4"
                md="3"
                v-for="(category, i) in categories"
                :key="i"
              >
                <v-checkbox
                  class="font-12"
                  hide-details="true"
                  v-model="category.checked"
                  :label="category.name"
                  :key="category._id"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
              @cancel="close"
              @next="submitHandler"
              text="Submit"
              :isValid="valid"
              :key="valid"
            ></buttons-experience>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
const StatType = {
  init: function (key, value) {
    return { key: key, value: value };
  }
};
const StatTypeEnum = {
  text: StatType.init("Text", 1),
  list: StatType.init("List", 0),
  allValues: function () {
    return [
      { title: StatTypeEnum.text.key, value: StatTypeEnum.text.value },
      { title: StatTypeEnum.list.key, value: StatTypeEnum.list.value }
    ];
  },
  init: function (value) {
    let mapped = this.allValues.map((item) => {
      return item.value == value;
    });
    if (mapped && mapped.length > 0) {
      return mapped[0];
    } else {
      return undefined;
    }
  }
};

export default {
  components: {
    ButtonsExperience
  },
  data: () => ({
    valid: false,
    categories: [],
    obj: {
      selections: [{ value: "", icon: undefined, id: 0 }],
      icon: undefined,
      inputType: undefined
    }
  }),
  computed: {
    types() {
      return StatTypeEnum.allValues();
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    addNewSelectHandler() {
      this.obj.selections.push({
        value: "",
        icon: undefined,
        id: Math.random()
      });
    },
    removeSelectHandler(event) {
      if (event._id) {
        this.obj.selections = [
          ...this.obj.selections.filter((i) => i._id != event._id)
        ];
      } else {
        this.obj.selections = [
          ...this.obj.selections.filter((i) => i.id != event.id)
        ];
      }
    },
    deleteFile(index) {
      this.obj.selections[index].icon = undefined;
    },
    async uploadIcon(file) {
      this.obj.icon = URL.createObjectURL(file);
      this.obj.newIcon = file;
    },
    async handleUpload(file, i) {
      this.obj.selections[i].icon = URL.createObjectURL(file);
      this.obj.selections[i].newIcon = file;
        if (this.obj.selections[i]._id) {
          let formDataSelection = new FormData();
          formDataSelection.append("icon", file);
          formDataSelection.append("statsId", this.obj._id);
          formDataSelection.append("selectionId", this.obj.selections[i]._id);
          let res = await this.$axios.post(
            "activityStats/selection-image",
            formDataSelection
          );
          if (res.data) {
            this.$store.dispatch("showSnack", {
              text: `uploaded successfully`,
              color: "success"
            });
          }
        }
    },
    async submitHandler() {
      this.obj.categories = this.categories
        .filter((category) => {
          return category.checked;
        })
        .map((category) => {
          return category._id;
        });

      let selectionsArr = [];
      if (this.obj.inputType == 0) {
        //   set unit to null & remove id from the obj that i added
        this.obj.selections.forEach(async (select) => {
          selectionsArr.push(
            select._id ? select : { value: select.value, icon: select.icon }
          );
        });
      }

      //   send request
      let formData = new FormData();
      if (this.obj.inputType == 1) {
        formData.append("unit", this.obj.unit);
      } else {
        formData.append("selections", JSON.stringify(selectionsArr));
      }
      formData.append("icon", this.obj.newIcon || this.obj.icon);
      formData.append("inputType", this.obj.inputType);
      formData.append("categories", this.obj.categories);
      formData.append("label", this.obj.label || this.obj.name.toUpperCase());
      formData.append("name", this.obj.name);

      let res = await this.$axios.post("activityStats", formData, {
        "Content-Type": "multipart/form-data"
      });
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Added successfully",
          color: "success"
        });
        this.$emit("reloadData");
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async fetchCategories() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.categories = data.categories;
        this.categories.forEach((cat) => (cat.checked = false));
      }
    }
  },
  async created() {
    await this.fetchCategories();
  }
};
</script>
