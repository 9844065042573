var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between b-bottom mb-6"},[_c('span',{staticClass:"d-block font-16 font-500"},[_vm._v("New Experience Category")]),_c('base-select',{attrs:{"items":_vm.$root.languages,"placeholder":"Language","returnObject":false},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('v-icon',{attrs:{"size":"20","color":"primary"},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_c('div',{staticClass:"px-6 mt-4 mb-10"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"font-13 grey--text"},[_vm._v("Stat Type")]),_c('base-select',{key:_vm.obj.inputType,attrs:{"items":_vm.types,"placeholder":"Stat Type","item-value":"value","itemText":"title","hideDetails":false,"returnObject":false,"rules":[
                (item) => {
                  return item != undefined;
                }
              ]},model:{value:(_vm.obj.inputType),callback:function ($$v) {_vm.$set(_vm.obj, "inputType", $$v)},expression:"obj.inputType"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0 pb-0",attrs:{"cols":"6","md":"6"}},[_c('label',{staticClass:"font-13 grey--text"},[_vm._v("Stat Name")]),_c('base-input',{attrs:{"placeholder":"Stat Name","hideDetails":false},model:{value:(_vm.obj.name[_vm.language]),callback:function ($$v) {_vm.$set(_vm.obj.name, _vm.language, $$v)},expression:"obj.name[language]"}})],1),(_vm.obj.inputType == 1)?_c('v-col',{staticClass:"mt-0 pt-0 pb-0",attrs:{"cols":"6","md":"6"}},[_c('label',{staticClass:"font-13 grey--text"},[_vm._v("Stat Unit")]),_c('base-input',{attrs:{"placeholder":"Stat Unit","hideDetails":false},model:{value:(_vm.obj.unit),callback:function ($$v) {_vm.$set(_vm.obj, "unit", $$v)},expression:"obj.unit"}})],1):_vm._e(),(_vm.obj.inputType == 1)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6"}},[_c('label',{staticClass:"font-13 grey--text mt-0"},[_vm._v("Main Icon")]),_c('div',{staticClass:"upload-item whitebg"},[_c('div',[(!_vm.obj.icon)?_c('v-icon',{attrs:{"color":"grayicon"}},[_vm._v("mdi-folder-multiple-image")]):_vm._e(),(_vm.obj.icon)?_c('img',{staticClass:"grayicon",attrs:{"src":_vm.obj.icon}}):_vm._e()],1),_c('v-file-input',{key:_vm.obj.icon && _vm.obj.icon.name ? _vm.obj.icon.name : _vm.obj.icon,attrs:{"hide-details":"true","rules":[
                  () => {
                    return _vm.obj.icon != undefined;
                  }
                ]},on:{"change":_vm.uploadIcon}})],1)]):_vm._e()],1),(_vm.obj.inputType == 0)?_c('div',{staticClass:"pl-2"},[_c('v-row',_vm._l((_vm.obj.selections),function(select,i){return _c('v-col',{key:i,staticClass:"pt-1 pb-0 pl-0 w-100",attrs:{"cols":"8"}},[_c('v-row',{staticClass:"justify-space-center"},[(_vm.obj.selections && _vm.obj.selections.length > 1)?_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"base-btn mt-5",on:{"click":function($event){return _vm.removeSelectHandler(select)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-minus-circle-outline")])],1)]):_vm._e(),_c('v-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"w-100"},[_c('label',{staticClass:"grey--text font-12 font-500"},[_vm._v("Option Name")]),_c('div',{staticClass:"select-input"},[_c('base-input',{attrs:{"placeholder":"name"},model:{value:(select.value[_vm.language]),callback:function ($$v) {_vm.$set(select.value, _vm.language, $$v)},expression:"select.value[language]"}})],1)])]),(select._id)?_c('v-col',{attrs:{"md":"5"}},[_c('label',{staticClass:"grey--text font-12 font-500"},[_vm._v("Option Icon")]),_c('div',{staticClass:"upload-item"},[(!select.icon)?_c('v-icon',{staticClass:"my-1",attrs:{"color":"grayicon"}},[_vm._v("mdi-folder-multiple-image")]):_vm._e(),_c('img',{attrs:{"alt":"","src":select.icon}}),_c('v-file-input',{key:select.icon,attrs:{"prepend-icon":"","hide-details":"true","rules":[
                        () => {
                          return select.icon != undefined;
                        }
                      ]},on:{"change":function($event){return _vm.handleUpload($event, i)}}})],1)]):_c('v-col',{attrs:{"md":"5"}},[_c('label',{staticClass:"grey--text font-12 font-500"},[_vm._v("Option Icon")]),_c('div',[_c('label',{staticClass:"black--text font-12"},[_vm._v("Save the Stat first")])])])],1)],1)}),1),_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"md":"6"}},[_c('div',[_c('div',{staticClass:"base-btn d-flex",on:{"click":_vm.addNewSelectHandler}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_c('div',{staticClass:"d-block ml-2"},[_vm._v("Add Option")])],1)])])],1)],1):_vm._e(),_c('label',{staticClass:"font-15 black--text mt-4 d-block"},[_vm._v("Categories")]),_c('v-row',_vm._l((_vm.categories),function(category,i){return _c('v-col',{key:i,attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-checkbox',{key:category._id,staticClass:"font-12",attrs:{"hide-details":"true","label":category.name},model:{value:(category.checked),callback:function ($$v) {_vm.$set(category, "checked", $$v)},expression:"category.checked"}})],1)}),1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('buttons-experience',{key:_vm.valid,attrs:{"text":"Submit","isValid":_vm.valid},on:{"cancel":_vm.close,"next":_vm.submitHandler}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }